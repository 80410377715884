import React from "react";
import "./auth.css";
import { AuthLeftComponent } from "../../features/auth/AuthLeftComponent";
import { images } from "../../constants/ImageImports";
import { AuthRightComponentReset } from "../../features/auth/AuthRightComponentReset";

const ResetPassword = () => {
  return (
    <div className="auth_wpr">
      <div
        className="auth_inr_wpr"
        style={{ backgroundImage: `url(${images?.loginImage})` }}
      >
        <AuthLeftComponent />
        <AuthRightComponentReset />
      </div>
    </div>
  );
};

export default ResetPassword;
