export const TOAST_MESSAGE = {
  WRONG_FILE: "Please choose a valid image format (jpg,jpeg,png,svg)",
  SIZE_ERROR: "Image size should not exceed 2MB",
  LOGIN: "Login successfully",
  LOGOUT: "Logout successfully",
  NO_INTERNET: "Please check you internet connection",
  UN_AUTHORISED: "Your account is blocked or deleted",
  UPDATE_USER: "Updated successfully",
  DELETE: "Deleted successfully",
  PASSWORD_UPDATED: "Password updated successfully",
  MESSAGE_SENT: "Message sent successfully",
  EMPTY_TEXT: "Please enter some text",
  WENT_WRONG: "Something went wrong",
  EMPTY_MESSAGE: "Please write some comment",
  NO_FILE: "Please select some file",
  TASK_UPDATED: "Task updated successfully",
  SUCCESS: "Success",
};

export const SIDE_BAR_MENU = [
  { link: "", title: "Dashboard", icon: "homeIcon" },
  { link: "todo-list", title: "To Do List", icon: "todoIcon" },
  { link: "notification", title: "Notification", icon: "notificationIcon" },
  { link: "setting", title: "Settings", icon: "settingIcon" },
  {
    link: "term-and-conditions",
    title: "Terms & Conditions",
    icon: "termIcon",
  },
  {
    link: "privacy-policy",
    title: "Privacy Policy",
    icon: "policyIcon",
  },
];
