import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { TodoList } from "../pages/todoList/TodoList";
import { Notification } from "../pages/notification/Notification";
import { Privacy } from "../pages/privacy/Privacy";
import { TermAndCondition } from "../pages/termAndCondition/TermAndCondition";
import { Setting } from "../pages/setting";
import { Login } from "../pages/auth/Login";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/resetPassword";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="notification" element={<Notification />} />
          <Route path="todo-list" element={<TodoList />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path="setting" element={<Setting />} />
          <Route path="term-and-conditions" element={<TermAndCondition />} />
        </Route>
        <Route path="/login" element={<PublicRoutes element={<Login />} />} />
        <Route
          path="forgot-password"
          element={<PublicRoutes element={<ForgotPassword />} />}
        />
        <Route
          path="reset-password"
          element={<PublicRoutes element={<ResetPassword />} />}
        />
        <Route path="*" element={<PublicRoutes element={<Login />} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
