import React from "react";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../components/common/sidebar/Sidebar";
import Header from "../components/common/header/Header";

function Maincontainer() {
  return (
    <>
      <div className="main_container">
        <Sidebar />
        <div className="ctnt_wpr">
          <Header />
          <Outlet />
        </div>
      </div>
    </>
  );
}
export default Maincontainer;
