import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import moment from 'moment';

const Clock = () => {
    const [time, setTime] = useState(new Date());
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTime(new Date());
      }, 1000);
      return () => clearInterval(interval);
    }, []);
  
    const formatTime = (time) => {  
      let hours = time.getHours();
      const minutes = time.getMinutes();
      const seconds = time.getSeconds();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours %= 12;
      hours = hours || 12; 
  
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${ampm}`;
    };
  
    return (
      <div>
        <h1>{formatTime(time)}</h1>
      </div>
    );
  };

export const DashCalender = () => {
    const [value, onChange] = useState(new Date());

  return (
    <div className='dash_calender_wpr'>
        <div className='dash_time'>
            <Clock />
            <span>
                { moment(value).format('DD MMM YYYY')}
            </span>
        </div>
        <Calendar onChange={onChange} value={value} className="dash_calender"/>
    </div>
  )
}
