import React from "react";
import { images } from "../../constants/ImageImports";

const isToday = (dateString) => {
  const currentDate = new Date().toISOString().slice(0, 10);
  const givenDate = dateString?.substring(0, 10);
  return givenDate === currentDate;
};

const isYesterday = (dateString) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const yesterdayDateString = yesterday.toISOString().slice(0, 10);
  const givenDate = dateString.substring(0, 10);
  return givenDate === yesterdayDateString;
};

const renderImage = (index) => {
  if ((index + 1) % 4 === 0) {
    return <img src={images.notificationYellow} alt="notification" />;
  } else if ((index + 1) % 2 === 0) {
    return <img src={images.notificationPink} alt="notification" />;
  }
  return <img src={images.notificationBlue} alt="notification" />;
};

export const NotificationItem = ({ index, text }) => {
  const formattedDate = (dateString) => {
    const today = isToday(dateString);
    const yesterday = isYesterday(dateString);
    if (today) {
      return "Today";
    } else if (yesterday) {
      return "Yesterday";
    } else {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);
      return `${formattedDate}`;
    }
  };

  return (
    <>
      <div className="ntf_Item_Time">
        {<b>{formattedDate(text?.[0]?.publishedDate)}</b>}
      </div>
      {text?.map((obj, i, arr) => {
        return (
          <div className="ntf_Item" key={`${obj.title}-${i}`}>
            <div className="ntf_Item_Icon lh-0">{renderImage(index)}</div>
            <div className="ntf_Item_text">
              <h5>{obj?.title}</h5>
              <p>{obj?.content}</p>
            </div>
          </div>
        );
      })}
    </>
  );
};
