import React from "react";
import { Navigate } from "react-router-dom";
import Maincontainer from "./Maincontainer";
import { useSelector } from "react-redux";

const PrivateRoutes = () => {
  const token = useSelector((state) => state?.authSlice?.token);

  // return token !== "" || token !== null || token !== undefined ? (
  return token !== "" ? (
    <Maincontainer />
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
