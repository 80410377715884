import React, { useEffect, useState } from "react";
import "./CurrentWorkingTask.css";
import DynamicAccordion from "../dynamicAccordion/DynamicAccordion";
import { postApi } from "../../../api/api.client";
import { APIS } from "../../../api/api.constant";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import NoDataFound from "../noDataFound";

export const CurrentWorkingTask = () => {
  const [week, setWeek] = useState("");
  const dispatch = useDispatch();
  const [currentTask, setCurrentTask] = useState([]);
  const [expanded, setExpanded] = useState("panel0");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(toggleLoader({ loader: true }));
      postApi(APIS.CURRENT_TASK, {})
        .then((res) => {
          setCurrentTask(res?.payload?.data?.todo);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    }, 1000);
  }, []);

  return (
    <div className="cwt_wpr">
      <div className="cwt_hdr">
        <div className="cwt_hdr_left">
          <h4>Current working task</h4>
          <p>{currentTask?.length} task</p>
        </div>
      </div>
      {currentTask?.length === 0 ? (
        <NoDataFound tableData={currentTask} />
      ) : (
        <div className="cwt_accordion">
          <DynamicAccordion
            data={currentTask}
            handleChange={handleChange}
            expanded={expanded}
          />
        </div>
      )}
    </div>
  );
};
