import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./Header.module.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Fade from "@mui/material/Fade";
import { images } from "../../../constants/ImageImports";
import { Link, useNavigate } from "react-router-dom";
import { showModal } from "../../../redux/slices/modalSlice";
import { MODAL_TYPE } from "../../../redux/constant/reduxConstant";
import { Modal } from "../../modals";
import { useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const pathName = window.location.pathname;
  const open = anchorEl;
  const currentUser = useSelector((state) => state?.authSlice);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutModalHandler = () => {
    dispatch(showModal(MODAL_TYPE?.LOGOUT));
  };

  return (
    <>
      <Modal />
      <header className={styles?.main_Hdr}>
        <div className={`cstm_Ctnr ${styles?.hdr_Inr}`}>
          <h3 className={styles?.hdr_Title}>
            {pathName === "/notification"
              ? "Notification"
              : pathName === "/term-and-conditions"
              ? "Terms & Conditions"
              : pathName === "/privacy-policy"
              ? "Privacy Policy"
              : pathName === "/todo-list"
              ? "To Do List"
              : pathName === "/setting"
              ? "Settings"
              : "Dashboard"}
          </h3>
          <Button
            id="logout_Btn"
            aria-controls={open ? "logout_Btn_Menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ padding: 0 }}
          >
            <Avatar sx={{ width: "30px", height: "30px" }}>
              {currentUser?.name?.[0]}
            </Avatar>
            <span className="text">{currentUser?.name}</span>
            <img src={images?.path} alt="icon" />
          </Button>
          <Menu
            id="logout_Btn_Menu"
            aria-labelledby="logout_Btn"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <div className={styles?.logout_Btn_Menu}>
              <Link to={"/"} className={styles?.logout_name}>
                {currentUser?.name}
              </Link>
              <a href={`mailto${currentUser?.email}`} className={styles?.email}>
                {currentUser?.email}
              </a>
              <div
                className={styles?.lgt_btn}
                onClick={() => {
                  logoutModalHandler();
                }}
              >
                <img src={images?.logout} alt="img" />
                logout
              </div>
            </div>
          </Menu>
        </div>
      </header>
    </>
  );
};

export default Header;
