import React from "react";
import "./modal.css";
import { useSelector } from "react-redux";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { Logout } from "./Logout";
import { Delete } from "./Delete";

export const Modal = () => {
  const { type, isModal } = useSelector((state) => state.modalSlice);

  const modalType = () => {
    switch (type) {
      case MODAL_TYPE.LOGOUT:
        return <Logout />;
      case MODAL_TYPE.DELETE:
        return <Delete />;
      default:
        return null;
    }
  };
  return <>{isModal ? <div className="cstm_mdl">{modalType()}</div> : ""}</>;
};
