import React, { useState, useEffect } from "react";
import { images } from "../../constants/ImageImports";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";

export const ContactUs = () => {
  const dispatch = useDispatch();
  const [adminData, setAdminData] = useState({});

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.CONTACT_US)
      .then((res) => {
        setAdminData(res?.payload?.data);
      })
      .catch((err) => {
        console.log("err==>", err);
      })
      .finally(() => dispatch(toggleLoader({ loader: false })));
  }, []);

  const ContactData = () => {
    return (
      <div className="prf_details contact_details">
        <div className="prf_description">
          <div className="prf_fields">
            <div className="prf_field_Item">
              <div className="prf_label">Address</div>
              <div className="prf_text">
                <span className="prf_Icon">
                  <img src={images?.locationIcon} alt="img" />
                </span>
                <div>{adminData?.address}</div>
              </div>
            </div>
            <div className="prf_field_Item">
              <div className="prf_label">Phone</div>
              <div className="prf_text">
                <span className="prf_Icon">
                  <img src={images?.profilePhoneIcon} alt="img" />
                </span>
                {adminData?.phone}
              </div>
            </div>
            <div className="prf_field_Item">
              <div className="prf_label">Email</div>
              <div className="prf_text">
                <span className="prf_Icon">
                  <img src={images?.profileMailIcon} alt="img" />
                </span>
                {adminData?.email}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="tab_wpr">
      <div className="tab_hdg_wpr">
        <h5 className="tab_hdg">Contact Us</h5>
      </div>
      <ContactData />
    </div>
  );
};
