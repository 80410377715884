import logo from "../components/assets/images/logoWhite.png";
import minLogo from "../components/assets/images/minLogoWhite.png";
import homeIcon from "../components/assets/images/home.png";
import minimizeIcon from "../components/assets/images/minimize.png";
import notificationIcon from "../components/assets/images/notification.png";
import settingIcon from "../components/assets/images/setting.png";
import termIcon from "../components/assets/images/term.png";
import policyIcon from "../components/assets/images/policy.png";
import todoIcon from "../components/assets/images/todo.png";
import path from "../components/assets/images/Path.png";
import logout from "../components/assets/images/logout.png";
import all from "../components/assets/images/all.svg";
import completed from "../components/assets/images/completed.svg";
import present from "../components/assets/images/present.svg";
import upcoming from "../components/assets/images/upcoming.svg";
import downloadIcon from "../components/assets/images/downloadIcon.svg";
import uploadIcon from "../components/assets/images/uploadIcon.svg";
import pdfIcon from "../components/assets/images/pdfIon.svg";
import uploadFileIcon from "../components/assets/images/uploadFileIcon.svg";
import stepperCheck from "../components/assets/images/stepperCheck.svg";
import notificationBlue from "../components/assets/images/notificationBlue.svg";
import notificationYellow from "../components/assets/images/notificationYellow.svg";
import notificationPink from "../components/assets/images/notificationPink.svg";
import privacy from "../components/assets/images/privacy.svg";
import termandcondition from "../components/assets/images/termandcondition.svg";
import infoIico from "../components/assets/images/infoIico.svg";
import changePasswordTabIcon from "../components/assets/images/changePasswordTabIcon.svg";
import deleteAccoutnIcon from "../components/assets/images/deleteAccoutnIcon.svg";
import contactIcon from "../components/assets/images/contactIcon.svg";
import profileMailIcon from "../components/assets/images/profileMailIcon.svg";
import profilePhoneIcon from "../components/assets/images/profilePhoneIcon.svg";
import profilePicIcon from "../components/assets/images/profilePicIcon.svg";
import camera from "../components/assets/images/camera.svg";
import locationIcon from "../components/assets/images/locationIcon.svg";
import loginImage from "../components/assets/images/loginImage.jpg";
import passwordInputLock from "../components/assets/images/passwordInputLock.svg";
import passwordInputUnLock from "../components/assets/images/passwordInputUnLock.svg";
import authBack from "../components/assets/images/authBack.jpg";
import dashWelcome from "../components/assets/images/dashWelcome.svg";
import statIcon1 from "../components/assets/images/statIcon1.svg";
import statIcon2 from "../components/assets/images/statIcon2.svg";
import statIcon3 from "../components/assets/images/statIcon3.svg";
import modalBG from "../components/assets/images/modalBG.svg";
import modelCross from "../components/assets/images/modelCross.svg";
import profileDummy from "./assests/profile_cover.jpg";

export const images = {
  modelCross,
  modalBG,
  statIcon1,
  statIcon2,
  statIcon3,
  dashWelcome,
  authBack,
  passwordInputUnLock,
  passwordInputLock,
  loginImage,
  locationIcon,
  camera,
  profilePicIcon,
  profileMailIcon,
  changePasswordTabIcon,
  profilePhoneIcon,
  deleteAccoutnIcon,
  contactIcon,
  infoIico,
  logo,
  minLogo,
  homeIcon,
  minimizeIcon,
  notificationIcon,
  settingIcon,
  termIcon,
  policyIcon,
  todoIcon,
  path,
  logout,
  all,
  upcoming,
  completed,
  present,
  downloadIcon,
  uploadIcon,
  pdfIcon,
  uploadFileIcon,
  stepperCheck,
  notificationPink,
  notificationYellow,
  notificationBlue,
  termandcondition,
  privacy,
  profileDummy,
};
