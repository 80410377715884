import React, { Fragment, useEffect, useState } from "react";
import { TaskItems } from "../../components/common/taskItems/TaskItems";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { useDispatch } from "react-redux";
import { NoDataFound } from "../../components/common";
import { getDueValue } from "../../utils";

export const ShowTab = () => {
  const dispatch = useDispatch();
  const [todoData, setTodoData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.GET_TO_LIST, {})
      .then((res) => {
        setTodoData(res?.payload?.data);
      })
      .catch((err) => { })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [refresh]);

  console.log("todoData", todoData)

  return (
    <>
      <div className="todo_list">
        <div className="todo_list">
          {todoData?.map((item, i, arr) => {

            let link = item?.assignment?.assignment?.video?.link1 || item?.assignment?.assignment?.video?.link2 || item?.assignment?.assignment?.video?.uploadedLink2 || item?.assignment?.assignment?.video?.uploadedLink1;

            let assignmentlink = item?.assignment?.assignment?.assignment?.link || item?.assignment?.assignment?.assignment?.uploadedLink

            return (
              <Fragment key={`${item.todo_type}-${i}`}>
                <p className="due_Date">{getDueValue(item?.task_start_date)}</p>
                {item?.tasks?.length > 0 &&
                  item?.tasks?.map((taskobj, index, taskarr) => {
                    return (
                      <TaskItems
                        item={taskobj}
                        key={i}
                        allData={item}
                        setRefresh={setRefresh}
                      />
                    );
                  })}

                {item?.type === "assignment" && assignmentlink && (
                  <TaskItems
                    item={item?.assignment}
                    type={item.type}
                    isAssignment={true}
                    allData={item}
                  // studWork={studWork}
                  />
                )}
                {item?.type === "video" && link && (
                  <TaskItems
                    item={item?.assignment}
                    type={item.type}
                    isAssignment={true}
                    allData={item}
                  // studWork={studWork}
                  />
                )}
                {/* {item?.assignment?.assessment_document && (
                  <TaskItems
                    item={item?.assignment}
                    isAssignment={true}
                    allData={item}
                    setRefresh={setRefresh}
                  />
                )} */}
              </Fragment>
            );
          })}
        </div>
      </div>
      {(todoData?.length === 0 || todoData === undefined) && (
        <NoDataFound
          tableData={todoData}
          style={{ height: "100vh", alignItem: "center" }}
        />
      )}
    </>
  );
};
