import React from 'react'
import { images } from '../../../constants/ImageImports'

export const UploadButton = ({showValue,actionShow}) => {
  return (
    <button className='btn_reset upload_btn' onClick={() => {actionShow(!showValue)}}>
         <img src={images?.uploadIcon} alt='image'/>
    </button>
  )
}
