import { toast } from "react-toastify";
export const getToast = (type, message) => {
  switch (type) {
    case "success":
      return toast.success(message);
    case "error":
      return toast.error(message);
    default:
      console.log("handle for this");
      return <></>;
  }
};

export const getValidDate = (dateString) => {
  if (dateString !== null && dateString !== undefined && dateString !== "") {
    const dateObject = new Date(dateString);
    const day = ("0" + dateObject.getDate()).slice(-2);
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const year = dateObject.getFullYear().toString().slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  return "N/A";
};

export const getDueValue = (inputDate) => {
  const [year, month, day] = inputDate.split("-");
  const currentDate = new Date(year, month - 1, day);
  const newDate = new Date(currentDate);
  const startDate = new Date(currentDate);
  newDate.setDate(newDate.getDate() + 7);
  const monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const startMonthString = monthNames[startDate.getMonth()];
  const monthString = monthNames[newDate.getMonth()];
  const startformattedDate = `${startDate.getDate()}-${startMonthString}-${startDate.getFullYear()}`;
  const formattedDate = `${newDate.getDate()}-${monthString}-${newDate.getFullYear()}`;

  return `Due to ${startformattedDate} to ${formattedDate}`;
};
