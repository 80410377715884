import React from "react";
import { useDispatch } from "react-redux";
import { MODAL_TYPE } from "../../redux/constant/reduxConstant";
import { showModal } from "../../redux/slices/modalSlice";

export const DeleteAccount = () => {
  const dispatch = useDispatch();
  const deleteModalHandler = () => {
    // dispatch(showModal(MODAL_TYPE?.DELETE));
  };

  return (
    <div className="tab_wpr delete_account">
      <div className="tab_hdg_wpr">
        <h5 className="tab_hdg">Delete your Account?</h5>
      </div>
      <p>Warning: Your account and all data will be permanently deleted.</p>
      <button className="pink_btn btn_reset" onClick={deleteModalHandler}>
        Delete Account
      </button>
    </div>
  );
};
