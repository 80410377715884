import React from "react";
import { useSelector } from "react-redux";

const NoDataFound = ({ tableData, style }) => {
  const isLoading = useSelector((state) => state?.loaderSlice?.loader);
  return (
    <>
      {(tableData?.length === 0 || tableData === undefined) && !isLoading ? (
        <div
          style={{
            ...style,
            display: "flex",
            justifyContent: "center",
            paddingTop: "40px",
            fontWeight: 800,
            color: "lightgray",
          }}
        >
          No Data Found
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "40px",
            fontWeight: 800,
            color: "lightgray",
          }}
        >
          Fetching Data...
        </div>
      )}
    </>
  );
};
export default NoDataFound;
