import React from "react";
import { images } from "../../../constants/ImageImports";
import { TOAST_MESSAGE } from "../../../constants/common";
import { getToast } from "../../../utils";

const ALLOW_IMAGES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/svg+xml",
  "image/svg",
];

const ImageUploader = ({ image, setFieldValue, setImage }) => {
  const imageHandler = (event) => {
    const getImage = event.target.files[0];
    const MBSzie = (getImage?.size / (1024 * 1024)).toFixed(2);

    if (!ALLOW_IMAGES.includes(getImage?.type)) {
      getToast("error", TOAST_MESSAGE.WRONG_FILE);
    } else {
      if (Number(MBSzie) > 2) {
        getToast("error", TOAST_MESSAGE.SIZE_ERROR);
      } else {
        const imgUrl = URL.createObjectURL(getImage);
        setFieldValue("profile_image", getImage);
        setImage(imgUrl);
      }
    }
  };
  return (
    <div className="prf_upload">
      <img
        crossOrigin="*"
        className="preview_img"
        src={image || images?.profileDummy}
        alt="img"
      />
      <img src={images?.camera} alt="img" />
      <input type="file" onChange={imageHandler} />
    </div>
  );
};
export default ImageUploader;
