import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FieldError } from "../../components/common";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { APIS } from "../../api/api.constant";
import { getToast } from "../../utils";
import { TOAST_MESSAGE } from "../../constants/common";
import { images } from "../../constants/ImageImports";
import { postApi } from "../../api/api.client";

const initialValues = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};

const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must be on atleast 8 characters, one uppercase letter, one lowercase, one no. and one special character*"
    )
    .required("Required*"),
  new_password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must be on atleast 8 characters, one uppercase letter, one lowercase, one no. and one special character*"
    )
    .required("Required*"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const ChangePassword = () => {
  const dispatch = useDispatch();

  const [isPasswordType, setIsPasswordType] = useState({
    old_password: true,
    new_password: true,
    confirm_password: true,
  });
  const onSubmit = (payload, { resetForm }) => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.CHANGE_PASSWORD, {
      ...payload,
    })
      .then((res) => {
        getToast("success", TOAST_MESSAGE.PASSWORD_UPDATED);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  };

  const CHANGE_PASSWORD_FIELDS = [
    {
      label: "Old Password",
      name: "old_password",
      type: isPasswordType?.old_password ? "password" : "text",
      placeholder: "Enter old password",
      icon: isPasswordType?.old_password
        ? images?.passwordInputLock
        : images?.passwordInputUnLock,
    },
    {
      label: "New Password",
      name: "new_password",
      type: isPasswordType?.new_password ? "password" : "text",
      placeholder: "Enter new password",
      icon: isPasswordType?.new_password
        ? images?.passwordInputLock
        : images?.passwordInputUnLock,
    },
    {
      label: "Confirm Password",
      name: "confirm_password",
      type: isPasswordType?.confirm_password ? "password" : "text",
      placeholder: "Enter confirm password",
      icon: isPasswordType?.confirm_password
        ? images?.passwordInputLock
        : images?.passwordInputUnLock,
    },
  ];

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: ChangePasswordSchema,
    enableReinitialize: true,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;

  return (
    <div className="tab_wpr">
      <form onSubmit={handleSubmit}>
        <div className="tab_hdg_wpr">
          <h5 className="tab_hdg">Change Password</h5>
          <div className="btn_group">
            <button className="pink_btn btn_reset" type="submit">
              Update Password
            </button>
          </div>
        </div>
        <div className="prf_fields edit_fields edit_fields-3 mt-40">
          {CHANGE_PASSWORD_FIELDS.map((fieldObj, i, arr) => {
            return (
              <>
                <div className="prf_field_Item" key={`${fieldObj?.name}-${i}`}>
                  <div className="prf_label">{fieldObj?.label}</div>
                  <div className="input_field icon">
                    <input
                      onBlur={() => {
                        setTouched({ ...touched, [fieldObj?.name]: true });
                      }}
                      value={values?.[fieldObj?.name]}
                      name={fieldObj?.name}
                      type={fieldObj?.type}
                      placeholder={fieldObj?.placeholder}
                      onChange={(e) => {
                        setFieldValue(fieldObj?.name, e.target.value);
                      }}
                    />
                    {fieldObj?.icon && (
                      <img
                        className="cursor_pointer"
                        src={fieldObj?.icon}
                        alt="img"
                        onClick={() =>
                          setIsPasswordType({
                            ...isPasswordType,
                            [fieldObj?.name]: !isPasswordType?.[fieldObj?.name],
                          })
                        }
                      />
                    )}
                  </div>
                  <FieldError
                    errors={errors}
                    touched={touched}
                    fieldObj={fieldObj}
                  />
                </div>
              </>
            );
          })}
        </div>
      </form>
    </div>
  );
};
