import React from "react";
import { images } from "../../../constants/ImageImports";

const downloadDummyPDF = (filePath) => {
  const link = document.createElement("a");
  const pdfPath = filePath;
  link.href = pdfPath;
  link.download = pdfPath;
  link.target = "_blank"; // Open in new tab/window
  link.rel = "noopener noreferrer"; // For security reasons

  document.body.appendChild(link);
  link.click();

  // Clean up after the download link is clicked
  document.body.removeChild(link);
};

export const DownloadButton = ({ filePath }) => {
  return (
    <button className="download_btn" onClick={() => downloadDummyPDF(filePath)}>
      <span>Download</span>
      <span>
        <img src={images?.downloadIcon} alt="image" />
      </span>
    </button>
  );
};
