import React, { useEffect, useState } from "react";
import { images } from "../../constants/ImageImports";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";

export const TermAndCondition = () => {
  const dispatch = useDispatch();
  const [getTermsConditions, setTermsConditions] = useState("");

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.TERMS_CONDITIONS, { content: "Test Content" })
      .then((res) => {
        setTermsConditions(res?.payload?.data?.content);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  return (
    <div className="privacy-page sec-padding min-vh">
      <div className="cstm_Ctnr">
        <div className="privacy_layout">
          <div className="privacy_heading">
            <div className="privacy_heading_icon">
              <img src={images?.termandcondition} alt="img" />
            </div>
            <h4>Terms & Conditions</h4>
          </div>
          <div className="privacy_content">
            <p>{getTermsConditions}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
