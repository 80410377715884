import React from "react";

export const InputText = ({ onTextChange }) => {
  return (
    <input
      type="text"
      className="cstm_Input"
      placeholder="Type here.."
      onChange={onTextChange}
    />
  );
};
