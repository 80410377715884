import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { TaskStepperVerticle } from "../taskItems/TaskStepperVerticle";
import { getDueValue } from "../../../utils";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const formatDate1 = (dateStr, daysToAdd = 0) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + daysToAdd);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  let dayWithOrdinal = day;
  const suffixes = ["th", "st", "nd", "rd"];
  const relevantDigits = day < 30 ? day % 20 : day % 30;
  const suffix = relevantDigits <= 3 ? suffixes[relevantDigits] : suffixes[0];
  dayWithOrdinal += suffix;

  return `${dayWithOrdinal} ${month}`;
};

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  let dayWithOrdinal = day;
  const suffixes = ["th", "st", "nd", "rd"];
  const relevantDigits = day < 30 ? day % 20 : day % 30;
  const suffix = relevantDigits <= 3 ? suffixes[relevantDigits] : suffixes[0];
  dayWithOrdinal += suffix;
  return `${dayWithOrdinal} ${month}`;
};

const DynamicAccordion = ({ data, handleChange, expanded }) => {
  return (
    <div>
      {data?.map((item, index) => {
        return (
          <>
            <span className="start_data">
              {formatDate(item?.task_start_date)}
            </span>
            {expanded ? (
              <span className="end_data">
                {formatDate1(item?.task_start_date, 6)}
              </span>
            ) : (
              <span className="end_data" style={{ visibility: "hidden" }}>
                hideen content
              </span>
            )}
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              className={
                item?.taskStatus?.is_submited ? "dyn_acr completed" : "dyn_acr"
              }
            >
              <AccordionSummary className="dyn_hdr">
                <h5>
                  <svg
                    width="28"
                    height="29"
                    viewBox="0 0 28 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 0.826172C6.28009 0.826172 0 7.10627 0 14.8262C0 22.5461 6.28009 28.8262 14 28.8262C21.7199 28.8262 28 22.5461 28 14.8262C28 7.10627 21.7199 0.826172 14 0.826172ZM21.0957 11.8594L13.5123 19.4426C13.2848 19.6701 12.9861 19.7846 12.6875 19.7846C12.3889 19.7846 12.0902 19.6701 11.8627 19.4426L8.07111 15.651C7.6148 15.1949 7.6148 14.4575 8.07111 14.0014C8.5272 13.5451 9.26439 13.5451 9.7207 14.0014L12.6875 16.9682L19.4461 10.2098C19.9022 9.75347 20.6394 9.75347 21.0957 10.2098C21.5518 10.6659 21.5518 11.4031 21.0957 11.8594Z"
                      fill="#9E9E9E"
                    />
                  </svg>
                  {item.task_title}
                </h5>
              </AccordionSummary>
              <AccordionDetails className="dyn_content">
                <TaskStepperVerticle itemData={item} />
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </div>
  );
};

export default DynamicAccordion;
