import React from "react";
import "./dashboard.css";
import Grid from "@mui/material/Grid";
import { CurrentWorkingTask } from "../../components/common/currentWorkingTask/CurrentWorkingTask";
import { DashCalender } from "../../features/dashboard/DashCalender";
import { images } from "../../constants/ImageImports";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const Dashboard = () => {
  const userData = useSelector((state) => state?.authSlice);
  const a = [
    {
      number: "178+",
      description: "Total Task",
    },
    {
      number: "20+",
      description: "Rework Task",
    },
    {
      number: "190+",
      description: "Complete Task",
    },
  ];

  return (
    <div className="todo-list-page sec-padding min-vh">
      <div className="cstm_Ctnr">
        <Grid container spacing={5}>
          <Grid item lg={8}>
            <div className="dash_wel dash_wel_bg white_bx">
              <div className="dash_rgt">
                <img src={images?.dashWelcome} alt="img" />
              </div>
              <div className="dash_left">
                <h3>Welcome {userData?.name}! </h3>
                <p>To check your tasks. Please click on the below button!</p>
                <h6>{userData?.class}</h6>

                <Link to="/todo-list" className="btn_reset pink_btn">
                  Review it
                </Link>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <DashCalender />
            <div className="mt-40">
              <CurrentWorkingTask />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
