import React from "react";
import { ShowTab } from "../../features/todoListTabContent/ShowTab";

export const TodoList = () => {
  return (
    <div className="todo-list-page sec-padding min-vh">
      <div className="cstm_Ctnr">
        <ShowTab />
      </div>
    </div>
  );
};
