import React from "react";
import logo from "../../components/assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { FieldError } from "../../components/common";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { getToast } from "../../utils";

const initialValues = {
  email: "",
};

const forgotSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email should be valid")
    .required("Required*")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
});

export const AuthRightComponentForgot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (payload, { resetForm }) => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.FORGOT_PASSWORD, { ...payload })
      .then((res) => {
        getToast("success", "Link sent successfully");
        resetForm();
      })
      .catch((err) => {
        console.log("err=>", err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: forgotSchema,
    enableReinitialize: true,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;

  const loginHandle = () => {
    navigate("/login");
  };

  return (
    <div className="auth_rgt">
      <form onSubmit={handleSubmit}>
        <div className="auth_ctnt">
          <img src={logo} alt="img" />
          <h4>Welcome to R.I.S.E. Academy </h4>
          <h6>Forgot Password</h6>
        </div>
        <div className="auth_frm">
          <div className="input_field_Item">
            <div className="input_label">Email</div>
            <div className="input_field">
              <input
                onBlur={() => {
                  setTouched({ email: true });
                }}
                value={values?.email}
                type="email"
                placeholder="Enter your email"
                name="email"
                onChange={(event) => {
                  setFieldValue("email", event?.target?.value);
                }}
              />
              <FieldError
                errors={errors}
                touched={touched}
                fieldObj={{ name: "email" }}
              />
            </div>
          </div>
        </div>

        <div className="lgn_btn_wpr">
          <button type="submit">Submit</button>
          <Link to="/">Back</Link>
        </div>
      </form>
    </div>
  );
};
