import React, { useEffect } from "react";
import "./components/assets/fonts/stylesheet.css";
import "./components/assets/css/style.css";
import Router from "./layout/Router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Loader } from "./components/common";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { Message } from "./components/common";
import { onMessage } from "firebase/messaging";
import { postApi } from "./api/api.client";
import { APIS } from "./api/api.constant";

const App = () => {
  const loading = useSelector((state) => state?.loaderSlice?.loader);
  const isAuthenticate = useSelector((state) => state?.authSlice?.token);

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BKI7kv1oN7BkOSDvCah9eM2mQIBCCx-b2VhhHmG_9-Hw7kI75etEaHvHUGTYy5MXVZ5UCJthFELQFrIEuhKGRvw",
      });
      if (token) {
        postApi(APIS.POST_CLOUD_TOKEN, { fmc: token })
          .then((res) => {
            console.log("token sent successfully");
          })
          .catch((errors) => {
            console.log("err during sending token to backend ");
          });
      }
    } else if (permission === "denied") {
      toast.error("You denied for the notification");
    }
  }

  onMessage(messaging, (payload) => {
    toast.info(<Message notification={payload.notification} />, {
      autoClose: 2000,
    });
  });

  useEffect(() => {
    setTimeout(() => {
      if (
        isAuthenticate !== null &&
        isAuthenticate !== undefined &&
        isAuthenticate !== ""
      ) {
        requestPermission();
      }
    }, 1000);
  }, [isAuthenticate]);

  return (
    <>
      {loading && <Loader />}
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
