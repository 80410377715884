import React from 'react';
import "./auth.css";
import { AuthLeftComponent } from '../../features/auth/AuthLeftComponent'
import { images } from '../../constants/ImageImports'
import { AuthRightComponentForgot } from '../../features/auth/AuthRightComponentForgot';

export const ForgotPassword = () => {

  return (
    <div className='auth_wpr' >
        <div className='auth_inr_wpr' style={{backgroundImage:`url(${images?.loginImage})`}}> 
          <AuthLeftComponent />
          <AuthRightComponentForgot />
        </div>
    </div>
  )
}
