import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { images } from "../../../constants/ImageImports";
import Tooltip from "@mui/material/Tooltip";
import { Link, NavLink } from "react-router-dom";
import { SIDE_BAR_MENU } from "../../../constants/common";

const Sidebar = () => {
  const [show, setShow] = useState(true);
  return (
    <div className={`${show ? styles.sidebar : styles.minSidebar}`}>
      <div className={styles.navBrand}>
        <NavLink to={"/"}>
          <img src={show ? images?.logo : images?.minLogo} alt="logo" />
        </NavLink>
      </div>
      <div
        className={`${
          show
            ? styles.minimizeBtn
            : styles.minimizeBtn + " " + styles.minimizeBtnCls
        }`}
        onClick={() => setShow(!show)}
      >
        <img src={images?.minimizeIcon} className="w-100" alt="minimize btn" />
      </div>
      <ul className={styles.sidebarNav}>
        {SIDE_BAR_MENU.map((obj, i, arr) => {
          return (
            <li key={`${obj?.title}-${i}`}>
              <NavLink to={obj?.link}>
                <span>
                  <Tooltip title={obj?.title} placement="bottom-start">
                    <img src={images?.[obj?.icon]} alt={obj?.title} />
                  </Tooltip>
                </span>
                <span className={styles.navtext}>{obj?.title}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
