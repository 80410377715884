import React from 'react'
import './setting.css'
import { Tabbing } from '../../components/common/tabbing/Tabbing'
import { SettingListTabbing } from '../../constants/TabbingArrays'

export const Setting = () => {
  return (
    <div className='setting-page sec-padding'>
        <div className="cstm_Ctnr">
            <div className='setting-tab'>
                <Tabbing tabs={SettingListTabbing}/>
            </div>
        </div>
    </div>
  )
}
