import React, { useState, useEffect } from "react";
import logo from "../../components/assets/images/logo.png";
import { useFormik } from "formik";
import { FieldError } from "../../components/common";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { getToast } from "../../utils";
import { TOAST_MESSAGE } from "../../constants/common";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { images } from "../../constants/ImageImports";
import * as Yup from "yup";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must be on atleast 8 characters, one uppercase letter, one lowercase, one no. and one special character*"
    )
    .required("Required*"),
  newPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const AuthRightComponentReset = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLinkExpiry, setIsLinkExpiry] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const URLEmail = searchParams.get("email");
  const URLToken = searchParams.get("token");
  const dispatch = useDispatch();

  const handleExpiryLink = async () => {
    postApi(APIS.CHECK_LINK, {
      email: URLEmail,
      token: URLToken,
    })
      .then((res) => {})
      .catch((err) => {
        setIsLinkExpiry(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleExpiryLink();
  }, []);

  const onSubmit = (values, { resetForm }) => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.SET_PASSWORD, {
      email: values?.email,
      password: values?.password,
      token: URLToken,
    })
      .then((res) => {
        getToast("success", TOAST_MESSAGE.PASSWORD_UPDATED);
        resetForm();
        navigate("/login");
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  };

  const formik = useFormik({
    initialValues: {
      email: URLEmail,
      password: "",
      newPassword: "",
    },
    onSubmit: onSubmit,
    validationSchema: ResetPasswordSchema,
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    touched,
    setTouched,
    isSubmitting,
  } = formik;

  return (
    <div className="auth_rgt">
      {isLinkExpiry && !isLoading ? (
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            fontSize: "40px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          Link has been expired !!
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="auth_ctnt">
            <img src={logo} alt="img" />
            <h4>Welcome to R.I.S.E. Academy </h4>
            <h6>Reset Password</h6>
          </div>
          <div className="auth_frm">
            <div className="input_field_Item">
              <div className="input_label">Email *</div>
              <div className="input_field">
                <input
                  disabled={true}
                  onBlur={() => {
                    setTouched({ ...touched, email: true });
                  }}
                  style={{ background: "lightgray" }}
                  value={values?.email}
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  onChange={(event) => {
                    setFieldValue("email", event?.target?.value);
                  }}
                />
              </div>
              <FieldError
                errors={errors}
                touched={touched}
                fieldObj={{ name: "email" }}
              />
            </div>
            <div className="input_field_Item">
              <div className="input_label">Password *</div>
              <div className="input_field icon">
                <input
                  onBlur={() => {
                    setTouched({ ...touched, password: true });
                  }}
                  value={values?.password}
                  name="password"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter new password"
                  onChange={(event) => {
                    setFieldValue("password", event?.target?.value);
                  }}
                />
                <img
                  className="cursor_pointer"
                  src={
                    isPasswordVisible
                      ? images?.passwordInputUnLock
                      : images?.passwordInputLock
                  }
                  alt="img"
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                />
              </div>
              <FieldError
                errors={errors}
                touched={touched}
                fieldObj={{ name: "password" }}
              />
            </div>
            <div className="input_field_Item">
              <div className="input_label">Confim Password *</div>
              <div className="input_field icon">
                <input
                  onBlur={() => {
                    setTouched({ ...touched, newPassword: true });
                  }}
                  value={values?.newPassword}
                  name="newPassword"
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  placeholder="Enter confirm password"
                  onChange={(event) => {
                    setFieldValue("newPassword", event?.target?.value);
                  }}
                />
                <img
                  className="cursor_pointer"
                  src={
                    isConfirmPasswordVisible
                      ? images?.passwordInputUnLock
                      : images?.passwordInputLock
                  }
                  alt="img"
                  onClick={() => {
                    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                  }}
                />
              </div>
              <FieldError
                errors={errors}
                touched={touched}
                fieldObj={{ name: "newPassword" }}
              />
            </div>
          </div>
          <div className="lgn_btn_wpr">
            <button type="submit">Submit</button>
          </div>
        </form>
      )}
    </div>
  );
};
