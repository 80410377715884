import { ChangePassword } from "../features/setting/ChangePassword";
import { ContactUs } from "../features/setting/ContactUs";
import { DeleteAccount } from "../features/setting/DeleteAccount";
import { Profile } from "../features/setting/Profile";
import { CompletedTask } from "../features/todoListTabContent/CompletedTask";
import { PresentTask } from "../features/todoListTabContent/PresentTask";
import { ShowTab } from "../features/todoListTabContent/ShowTab";
import { UpcomingTask } from "../features/todoListTabContent/UpcomingTask";
import { images } from "./ImageImports";

export const TodoListTabbing =  [
  { title: "Show All", 
    content: <ShowTab /> ,
    icon: images?.all,
    task:10, 
  },
  { title: "Present Task", 
    content: <PresentTask />,
    icon: images?.present,
    task:8, 
  },
  { title: "Upcoming", 
    content: <UpcomingTask />,
    icon: images?.upcoming,
    task:5, 
  },
  { title: "Completed", 
    content: <CompletedTask />,
    icon: images?.completed,
    task:12, 
  }
];

export const SettingListTabbing =  [
  { title: "Edit Profile", 
    content: <Profile /> ,
    icon: images?.all,
  },
  { title: "Change Password", 
    content: <ChangePassword />,
    icon: images?.changePasswordTabIcon,
  },
  { title: "Contact Us", 
    content: <ContactUs />,
    icon: images?.contactIcon,
  },
  { title: "Delete Account", 
    content: <DeleteAccount />,
    icon: images?.deleteAccoutnIcon,
  }
];
