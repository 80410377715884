import * as Yup from "yup";
const Fields = {
  name: {
    label: "Name*",
    name: "name",
    placeholder: "Enter name",
    type: "text",
    component: "input",
    style: { minWidth: "451px", maxWidth: "451px" },
  },
  phone: {
    label: "Phone",
    name: "phone",
    placeholder: "Enter phone number",
    type: "tel",
    component: "input",
    style: { minWidth: "451px", maxWidth: "451px" },
  },
  email: {
    isDisabled: true,
    label: "Email",
    name: "email",
    placeholder: "Enter email",
    type: "email",
    component: "input",
    style: { minWidth: "451px", maxWidth: "451px" },
  },
  address: {
    label: "Address",
    name: "address",
    placeholder: "Enter address",
    type: "text",
    component: "textarea",
    style: { width: "980px", height: "132px" },
  },
  age: {
    label: "Age",
    name: "age",
    placeholder: "Enter age",
    type: "number",
    component: "input",
    style: { minWidth: "451px", maxWidth: "451px" },
  },
  gender: {
    label: "Pronouns*",
    name: "gender",
    placeholder: "Select pronouns*",
    component: "select",
    options: ["He/Him", "She/Her", "They/Them", "Other"],
    style: { minWidth: "451px", maxWidth: "451px" },
  },
};

export const Schema = {
  name: Yup.string()
    .min(3, "Username should be between (2-40) characters")
    .max(39, "Username should be between (2-40) characters")
    .matches(/^[A-Za-z\s]+$/, "Only alphabets and spaces are allowed")
    .required("Required*"),

  email: Yup.string()
    .email("Email should be valid")
    .required("Required*")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
  // phone: Yup.string()
  //   .length(10, "Phone no. should be of 10 digits")
  //   .matches(/^[0-9]+$/, "Phone number must contain only digits")
  //   .required("Required*"),
  // address: Yup.string().max(150, "Address must not exceed 150 characters."),
};

export const USER_UPDATE_FORM = {
  FIELDS: [
    Fields.name,
    Fields.phone,
    Fields.email,
    Fields.gender,
    Fields.address,
    Fields.age,
  ],
  SCHEMA: Yup.object().shape({
    name: Schema?.name,
    // phone: Schema?.phone,
    // address: Schema.address,
  }),
  INITIAL_VALUES: {
    name: "",
    phone: "",
    email: "",
    profile_image: "",
    address: "",
    age: "",
    gender: "",
  },
};
