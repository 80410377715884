import React, { useEffect, useState } from "react";
import { NotificationItem } from "../../features/notification/NotificationItem";
import "./Notification.css";
import { postApi } from "../../api/api.client";
import { APIS } from "../../api/api.constant";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";

export const Notification = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.GET_NOTIFICATION)
      .then((res) => {
        const groupedByDate = Object.values(
          res?.payload?.data.reduce((acc, obj) => {
            if (obj.publishedDate) {
              const date = obj.publishedDate.split("T")[0];
              if (!acc[date]) {
                acc[date] = [];
              }
              acc[date].push(obj);
            }
            return acc;
          }, {})
        );

        setData(groupedByDate);
      })
      .catch((err) => {
        console.log("err==>", err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, []);

  return (
    <div className="notification-page sec-padding">
      <div className="cstm_Ctnr">
        <div className="ntf_List">
          {data?.length === 0 ? (
            <b
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              NO DATA FOUND
            </b>
          ) : (
            data?.map((item, i) => (
              <NotificationItem text={item} index={i} key={i} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};
