import React from "react";
import FieldError from "../fieldError";

// import { Select } from "antd";

const MixComponents = ({
  fieldObj,
  values,
  errors,
  setFieldValue,
  touched,
  setTouched,
  style,
}) => {
  return (
    <>
      {fieldObj?.component === "input" && (
        <>
          <input
            disabled={fieldObj?.isDisabled}
            onBlur={() => {
              setTouched({ ...touched, [fieldObj?.name]: true });
            }}
            style={{
              ...fieldObj?.style,
              background: fieldObj?.isDisabled && "lightgray",
            }}
            value={values?.[fieldObj?.name]}
            name={fieldObj?.name}
            type={fieldObj?.type}
            placeholder={fieldObj?.placeholder}
            onChange={(e) => {
              setFieldValue(fieldObj?.name, e.target.value);
            }}
          />
          <FieldError errors={errors} touched={touched} fieldObj={fieldObj} />
        </>
      )}
      {fieldObj?.component === "select" && (
        <>
          <select
            onBlur={() => {
              setTouched({ ...touched, [fieldObj?.name]: true });
            }}
            name={fieldObj?.name}
            style={{
              ...fieldObj?.style,
              cursor: "pointer",
              background: fieldObj?.isDisabled && "lightgray",
            }}
            placeholder={fieldObj?.placeholder}
            defaultValue={values?.[fieldObj?.name]}
            onChange={(event) => {
              setFieldValue(fieldObj?.name, event?.target?.value);
            }}
          >
            {fieldObj?.options?.map((value, i, arr) => {
              return (
                <option
                  value={value}
                  key={value}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  {value}
                </option>
              );
            })}
          </select>

          <FieldError errors={errors} touched={touched} fieldObj={fieldObj} />
        </>
      )}
      {fieldObj?.component === "textarea" && (
        <>
          <textarea
            onBlur={() => {
              setTouched({ ...touched, [fieldObj?.name]: true });
            }}
            key={fieldObj?.name}
            name={fieldObj?.name}
            value={values?.[fieldObj?.name]}
            style={{
              padding: "10px 20px",
              ...fieldObj?.style,
              background: fieldObj?.isDisabled && "lightgray",
            }}
            placeholder={fieldObj?.placeholder}
            onChange={(e) => {
              setFieldValue(fieldObj?.name, e.target.value);
            }}
          />
          <FieldError errors={errors} touched={touched} fieldObj={fieldObj} />
        </>
      )}
    </>
  );
};

export default MixComponents;
