import React, { useEffect, useState } from "react";
import { postApi } from "../../api/api.client";
import { APIS, BASE_URL } from "../../api/api.constant";
import { USER_UPDATE_FORM } from "../../constants/formik";
import { useFormik } from "formik";
import { MixComponents, ImageUploader } from "../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { images } from "../../constants/ImageImports";
import { getToast } from "../../utils";
import { TOAST_MESSAGE } from "../../constants/common";
import { setUser } from "../../redux/slices/authSlice";

const TAB_TYPES = {
  INFO: "info",
  EDIT: "edit",
};

const TAB = [{ type: TAB_TYPES.INFO }, { type: TAB_TYPES.EDIT }];

const getOtherTabType = (currentTabType) => {
  if (currentTabType === TAB_TYPES.INFO) {
    return TAB_TYPES.EDIT;
  } else if (currentTabType === TAB_TYPES.EDIT) {
    return TAB_TYPES.INFO;
  } else {
    return null;
  }
};
const USER_INFO = [
  {
    label: "Phone",
    key: "phone",
    icon: images?.profilePhoneIcon,
  },
  {
    label: "Email",
    key: "email",
    icon: images?.profileMailIcon,
  },
  {
    label: "Pronouns",
    key: "gender",
    icon: images?.infoIico,
    size: "14px",
  },
];

const ProfileData = ({ userData }) => {
  return (
    <div className="prf_details prf_tab">
      <div className="prf_img">
        <img
          crossOrigin="*"
          src={
            userData?.photo !== null &&
            userData?.photo !== undefined &&
            userData?.photo !== ""
              ? userData?.photo
              : images.profileDummy
          }
          alt="img"
        />
      </div>
      <div className="prf_description">
        <h5 style={{ textTransform: "capitalize" }}>{userData?.name}</h5>
        <div className="prf_fields">
          {USER_INFO.map((obj, i, arr) => {
            return (
              <div className="prf_field_Item" key={`${obj?.icon}-${i}`}>
                <div className="prf_label">{obj?.label}</div>
                <div className="prf_text" style={{ display: "flex" }}>
                  <span className="prf_Icon">
                    <img
                      src={obj?.icon}
                      alt={obj?.label}
                      style={{ height: obj?.size && obj?.size }}
                    />
                  </span>
                  {userData?.[obj?.key] ? userData?.[obj?.key] : "N/A"}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ProfileEditData = ({
  image,
  values,
  errors,
  setFieldValue,
  touched,
  setTouched,
  setImage,
}) => {
  return (
    <div className="prf_details edit_details">
      <ImageUploader
        image={image}
        setFieldValue={setFieldValue}
        setImage={setImage}
      />
      <form>
        <div className="prf_description">
          <div className="prf_fields edit_fields">
            {USER_UPDATE_FORM.FIELDS.map((fieldObj, i, arr) => {
              return (
                <div className="prf_field_Item" key={`${fieldObj?.name}-${i}`}>
                  <div className="prf_label">{fieldObj?.label}</div>
                  <div className="prf_text">
                    <MixComponents
                      fieldObj={fieldObj}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      setTouched={setTouched}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </form>
    </div>
  );
};

export const Profile = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TAB?.[0]?.type);
  const [userData, setUserData] = useState({});
  const currentUser = useSelector((state) => state?.authSlice);
  const [image, setImage] = useState(null);

  useEffect(() => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.GET_USER_DETAILS, {})
      .then((res) => {
        if (
          res?.payload?.data?.photo !== null ||
          res?.payload?.data?.photo !== undefined ||
          res?.payload?.data?.photo !== ""
        ) {
          setImage(userData?.photo);
        }
        setUserData(res?.payload?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  }, [activeTab]);

  const onSubmit = (payload) => {
    if (
      Number(values?.age < 12 || Number(values?.age) > 150) &&
      values?.age !== ""
    ) {
      getToast("error", "Please enter age between 11 and 150");
    } else {
      const formData = new FormData();
      for (const key in payload) {
        formData.append(key, payload[key]);
      }
      dispatch(toggleLoader({ loader: true }));
      postApi(APIS.UPDATE_USER, Object.fromEntries(formData), true)
        .then((res) => {
          getToast("success", TOAST_MESSAGE.UPDATE_USER);
          setActiveTab(getOtherTabType(activeTab));
          dispatch(
            setUser({
              ...currentUser,
              name: payload?.name,
              email: payload?.email,
              isAdmin: false,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(toggleLoader({ loader: false }));
        });
    }
  };

  const formik = useFormik({
    initialValues: userData || USER_UPDATE_FORM?.INITIAL_VALUES,
    onSubmit: onSubmit,
    validationSchema: USER_UPDATE_FORM?.SCHEMA,
    enableReinitialize: true,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="tab_wpr">
        <div className="tab_hdg_wpr">
          <h5 className="tab_hdg">
            {activeTab === TAB_TYPES.EDIT ? "Edit Profile" : "Profile"}
          </h5>
          <div className="btn_group d-flex gap-10px">
            {activeTab === TAB_TYPES.EDIT && (
              <button
                type="button"
                className="theme_btn btn_reset"
                onClick={() => setActiveTab(getOtherTabType(activeTab))}
              >
                Back
              </button>
            )}
            <button
              className="pink_btn btn_reset"
              type={activeTab === TAB_TYPES.EDIT ? "submit" : "button"}
              onClick={(event) => {
                if (activeTab === TAB_TYPES.INFO) {
                  event.stopPropagation();
                  event.preventDefault();
                  setActiveTab(getOtherTabType(activeTab));
                }
              }}
            >
              {activeTab === TAB_TYPES.EDIT ? "Save" : "Edit Profile"}
            </button>
          </div>
        </div>
        {activeTab === TAB_TYPES.EDIT ? (
          <ProfileEditData
            image={image}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            touched={touched}
            setTouched={setTouched}
            setImage={setImage}
          />
        ) : (
          <ProfileData userData={userData} image={image} />
        )}
      </div>
    </form>
  );
};
