import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

const PublicRoutes = ({ element, ...rest }) => {
  const token = useSelector((state) => state?.authSlice?.token);

  return <>{token === "" ? element : <Navigate to="/" />}</>;
};

export default PublicRoutes;
