import React, { useState } from "react";
import logo from "../../components/assets/images/logo.png";
import { images } from "../../constants/ImageImports";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FieldError } from "../../components/common";
import { setUser } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { postApi } from "../../api/api.client.js";
import { APIS } from "../../api/api.constant.js";
import { useNavigate } from "react-router-dom";
import { getToast } from "../../utils/index.js";
import { TOAST_MESSAGE } from "../../constants/common.js";
import { toggleLoader } from "../../redux/slices/loaderSlice.js";

// teststudent@yopmail.com

const initialValues = {
  email: "",
  password: "",
};

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email should be valid")
    .required("Required*")
    .matches(
      /^[^\s@]+@[^\s@]+\.(com|ca|io|uk|aus|in)$/,
      "Invalid email format"
    ),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password must be on atleast 8 characters, one uppercase letter, one lowercase, one no. and one special character*"
    )
    .required("Required*"),
});

export const AuthRightComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLocked, setIsLocked] = useState(true);

  const onSubmit = (payload) => {
    dispatch(toggleLoader({ loader: true }));
    postApi(APIS.LOGIN, {
      ...payload,
    })
      .then((res) => {
        const userData = res?.payload?.data?.user;
        const token = res?.payload?.data?.token;
        dispatch(
          setUser({
            name: userData?.name || "",
            token: token,
            email: userData?.email,
            isAdmin: false,
            role: userData?.role,
            userId: userData?.userId,
            class: userData?.class,
          })
        );
        navigate("/");
        getToast("success", TOAST_MESSAGE.LOGIN);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(toggleLoader({ loader: false }));
      });
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: loginSchema,
    enableReinitialize: true,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, setTouched } =
    formik;

  const FORM_FIELDS = [
    {
      label: "Email",
      placeholder: "Enter your email",
      name: "email",
      type: "text",
      inputClass: "input_field",
    },
    {
      label: "Password",
      placeholder: "Enter your password",
      name: "password",
      type: isLocked ? "password" : "text",
      inputClass: "input_field icon",
      icon: isLocked ? images?.passwordInputLock : images?.passwordInputUnLock,
    },
  ];

  return (
    <div className="auth_rgt">
      <div className="auth_ctnt">
        <img src={logo} alt="img" />
        <h4>Welcome to R.I.S.E. Academy </h4>
        <h6>Student Login</h6>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="auth_frm">
          {FORM_FIELDS.map((fieldObj, i, arr) => {
            return (
              <div className="input_field_Item" key={`${fieldObj?.name}-${i}`}>
                <div className="input_label">{fieldObj?.name}</div>
                <div className={fieldObj?.inputClass}>
                  <input
                    onBlur={() => {
                      setTouched({ ...touched, [fieldObj?.name]: true });
                    }}
                    value={values?.[fieldObj?.name]}
                    name={fieldObj?.name}
                    type={fieldObj?.type}
                    placeholder={fieldObj?.placeholder}
                    onChange={(e) => {
                      setFieldValue(fieldObj?.name, e.target.value);
                    }}
                  />
                  {fieldObj?.icon && (
                    <img
                      className="cursor_pointer"
                      src={fieldObj?.icon}
                      alt="img"
                      onClick={() => setIsLocked(!isLocked)}
                    />
                  )}
                </div>
                <FieldError
                  errors={errors}
                  touched={touched}
                  fieldObj={fieldObj}
                />
              </div>
            );
          })}
        </div>
        <div className="lgn_btn_wpr">
          <button type="submit">LOGIN</button>
          <Link to="/forgot-password">Forgot Password</Link>
        </div>
      </form>
    </div>
  );
};
