import React, { useEffect } from "react";
import "./auth.css";
import { AuthLeftComponent } from "../../features/auth/AuthLeftComponent";
import { AuthRightComponent } from "../../features/auth/AuthRightComponent";
import { images } from "../../constants/ImageImports";
import { getToast } from "../../utils";
import { TOAST_MESSAGE } from "../../constants/common";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const myParam = query.get("success");

  useEffect(() => {
    localStorage.clear();

    if (myParam === "true") {
      getToast("success", TOAST_MESSAGE.LOGOUT);
      navigate("/login");
    }
    if (myParam === "un-auth") {
      getToast("error", TOAST_MESSAGE.UN_AUTHORISED);
      navigate("/login");
    }
  }, []);

  return (
    <div className="auth_wpr">
      <div
        className="auth_inr_wpr"
        style={{ backgroundImage: `url(${images?.loginImage})` }}
      >
        <AuthLeftComponent />
        <AuthRightComponent />
      </div>
    </div>
  );
};
