import React from "react";
import { images } from "../../../constants/ImageImports";

export const UploadInput = ({ onChange }) => {
  return (
    <div className="upload_input">
      <img src={images?.uploadFileIcon} alt="img" />
      <span className="file_text">Select a file or drag and drop here</span>
      <span className="file-select">Select file</span>
      <input type="file" accept="pdf" onChange={onChange} />
    </div>
  );
};
