import React from "react";
import { images } from "../../../constants/ImageImports";
import { CustomPopover } from "../customPopover/CustomPopover";
import { getValidDate } from "../../../utils";

export const TaskStepper = ({ itemData }) => {
  return (
    <ul className="stepper_list">
      <li
        className={`${
          itemData?.taskStatus?.is_submited
            ? "stepper_item submitted"
            : "stepper_item"
        }`}
      >
        <div className="step_name">Submitted</div>
        <div className="step_mid">
          {itemData?.taskStatus?.is_submited ? (
            <div className="color_dot"></div>
          ) : (
            <img src={images?.stepperCheck} alt="check" />
          )}
          <span></span>
        </div>
        <div className="step_date">
          {getValidDate(itemData?.taskStatus?.submited_date)}
          <CustomPopover
            icon={images?.infoIico}
            alt="infoIico"
            text={itemData?.taskStatus?.submited_text || "N/A"}
          />{" "}
        </div>
      </li>
      {itemData?.taskStatus?.is_rework && (
        <li
          className={`${
            itemData?.taskStatus?.is_rework
              ? "stepper_item rework"
              : "stepper_item"
          }`}
        >
          <div className="step_name">Need Rework</div>
          <div className="step_mid">
            {itemData?.taskStatus?.is_rework ? (
              <div className="color_dot"></div>
            ) : (
              <img src={images?.stepperCheck} alt="check" />
            )}
            <span></span>
          </div>
          <div className="step_date">
            {getValidDate(itemData?.taskStatus?.rework_date)}
            <CustomPopover
              icon={images?.infoIico}
              alt="infoIico"
              text={itemData?.taskStatus?.rework_text || "N/A"}
            />
          </div>
        </li>
      )}

      <li
        className={`${
          itemData?.taskStatus?.is_approved
            ? "stepper_item approved"
            : "stepper_item"
        }`}
      >
        <div className="step_name">Approved by teacher</div>
        <div className="step_mid">
          {itemData?.taskStatus?.is_approved ? (
            <div className="color_dot"></div>
          ) : (
            <img src={images?.stepperCheck} alt="check" />
          )}
          <span></span>
        </div>
        <div className="step_date">
          {getValidDate(itemData?.taskStatus?.approved_date)}
          <CustomPopover
            icon={images?.infoIico}
            alt="infoIico"
            text={itemData?.taskStatus?.approved_text || "N/A"}
          />
        </div>
      </li>
    </ul>
  );
};
